import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-points',
  templateUrl: './points.component.html',
  styleUrls: ['./points.component.scss'],
})
export class PointsComponent implements OnInit {
  @Input() punto: any;
  config = JSON.parse(localStorage.getItem('ajustes_tepremia'));

  constructor(private modalController: ModalController) { }

  ngOnInit() {

  }

  async closeModal() {
    await this.modalController.dismiss({});
  }
}
