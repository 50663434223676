import {NgModule} from '@angular/core';
import {NumerosPipe} from './numeros.pipe';
import {TituloCortoPipe} from './titulo-corto.pipe';
import {ImagenPipe} from './imagen.pipe';
import { CreditCardPipe } from './credit-card.pipe';

@NgModule({
  declarations: [
    NumerosPipe,
    TituloCortoPipe,
    ImagenPipe,
    CreditCardPipe
  ],
  imports: [],
  exports: [
    NumerosPipe,
    TituloCortoPipe,
    ImagenPipe,
    CreditCardPipe
  ],
  providers: [
    NumerosPipe,
    TituloCortoPipe,
    ImagenPipe
  ],
})
export class PipesModule {
}
