import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NotificationComponent} from './modal/notification/notification.component';
import { DetailComponent } from './modal/detail/detail.component';
import { BuyComponent } from './modal/buy/buy.component';
import { BuyPointsComponent } from './modal/buy-points/buy-points.component';
import { AddressComponent } from './cards/address/address.component';
import { ComprarComponent } from './button/comprar/comprar.component';
import { InfoComponent } from './popover/info/info.component';
import { PointsComponent } from './modal/points/points.component';
import { GeneralComponent } from './headers/general/general.component';
import { NotiComponent } from './headers/notification/notification.component';
import { PerfilComponent } from './headers/perfil/perfil.component';
import { WinnerComponent } from './modal/winner/winner.component';
import { DetailsComponent } from "./headers/detail/detail.component";

import { PipesModule } from '../pipes/pipes.module';
import { SimpleMaskModule } from 'ngx-ion-simple-mask';
import { CountUpModule } from 'ngx-countup';

@NgModule({
  declarations: [
    NotificationComponent,
    DetailComponent,
    BuyComponent,
    ComprarComponent,
    BuyPointsComponent,
    AddressComponent,
    InfoComponent,
    PointsComponent,
    GeneralComponent,
    PerfilComponent,
    WinnerComponent,
    DetailsComponent,
    NotiComponent
  ],
  exports: [
    NotificationComponent,
    DetailComponent,
    BuyComponent,
    ComprarComponent,
    BuyPointsComponent,
    AddressComponent,
    InfoComponent,
    PointsComponent,
    GeneralComponent,
    PerfilComponent,
    WinnerComponent,
    DetailsComponent,
    NotiComponent
  ],
  imports: [
  CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    PipesModule,
    ReactiveFormsModule,
    SimpleMaskModule,
    CountUpModule,
  ],
  providers: [
    NotificationComponent,
    DetailComponent,
    BuyComponent,
    ComprarComponent,
    BuyPointsComponent,
    AddressComponent,
    InfoComponent,
    PointsComponent,
    GeneralComponent,
    PerfilComponent,
    WinnerComponent,
    DetailsComponent,
    NotiComponent
  ]
})
export class ComponentsModule {

}
