// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    stripe_key: 'pk_live_GoXnlxoBs1su2NukcWVEal2e',
    // stripe_key: 'pk_test_nlpOFFsWusmwaEzw7iR0hoPf',
    // url: 'https://tepremia-admin2020.test/api',
    // url: 'https://d422d706616d.ngrok.io/api',
    url: 'https://admin.tepremia.com.mx/api',
    // url: 'https://tepremia.monocilindrero.com/api',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
