import { Injectable, Inject } from '@angular/core';
import { DOCUMENT} from '@angular/common';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import * as Color from 'color';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(@Inject(DOCUMENT) private document: Document, private statusBar: StatusBar) { }

  setStatusBarColor(color) {
    this.statusBar.backgroundColorByHexString(color);
  }

  setDefaultTheme() {
    this.document.documentElement.style.cssText = '';
  }

  setTheme(theme) {
    const cssText = CSSTextGenerator(theme);
    this.setGlobalCss(cssText);
  }

  setVariable(key, value) {
    this.document.documentElement.style.setProperty(key, value);
  }

  setGlobalCss(cssText) {
    this.document.documentElement.style.cssText = cssText;
  }
}

const defaults = {
  primary: '#1EADF4',
  secondary: '#F2C11C',
  tertiary: '#0277bd'
};

function CSSTextGenerator(colors) {
  colors = {...defaults, ...colors};

  const {
    primary,
    secondary,
    tertiary,
  } = colors;

  const shadeRatio = 0.1;
  const tintRatio = 0.1;

  return `
    --ion-color-primary: ${primary};
    --ion-color-primary-rgb: 30,173,244;
    --ion-color-primary-contrast: ${contrast(primary)};
    --ion-color-primary-contrast-rgb: 0,0,0;
    --ion-color-primary-shade: ${Color(primary).darken(shadeRatio)};
    --ion-color-primary-tint: ${Color(primary).lighten(tintRatio)};

    --ion-color-secondary: ${secondary};
    --ion-color-secondary-rgb: 242,193,28;
    --ion-color-secondary-contrast: ${contrast(secondary)};
    --ion-color-secondary-contrast-rgb: 0,0,0;
    --ion-color-secondary-shade: ${Color(secondary).darken(shadeRatio)};
    --ion-color-secondary-tint: ${Color(secondary).lighten(shadeRatio)};

    --ion-color-tertiary: ${tertiary};
    --ion-color-tertiary-rgb: 2,119,189;
    --ion-color-tertiary-contrast: ${contrast(tertiary)};
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: ${Color(tertiary).darken(shadeRatio)};
    --ion-color-tertiary-tint: ${Color(tertiary).lighten(shadeRatio)};
  `;
}

function contrast(color, ratio = .8) {
  color = Color(color);
  return color.isDark() ? '#FFFFFF' : '#000000';
}
