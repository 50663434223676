import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'numeros',
})
export class NumerosPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: number, ...args) {
    return value.toFixed(2).toString();
  }
}
