import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree, Router, NavigationExtras
} from '@angular/router';
import { Observable } from 'rxjs';
import { UtilsService } from './utils.service';
import { ThemeService } from './theme.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private utils: UtilsService, private router: Router, private themeService: ThemeService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (state.url.includes('/products') || state.url.includes('/about/')) {
      this.themeService.setStatusBarColor(JSON.parse(localStorage.getItem('ajustes_tepremia')).color1);
    } else {
      this.themeService.setStatusBarColor('#ffffff');
    }

    const isLogged = localStorage.getItem('isLoged');
    if (isLogged) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = localStorage.getItem('user');
    if (user) {
      return true;
    } else {
      this.router.navigate([state.url + '/welcome']);
      return false;
    }
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
