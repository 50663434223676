import { Component } from "@angular/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Device } from "@ionic-native/device/ngx";

import { ThemeService } from "./services/theme.service";
import { UtilsService } from "./services/utils.service";
import { NotificationsService } from "./services/observers/notifications.service";

import { Router } from '@angular/router';

import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";

import { HttpService } from './services/http.service';
import { UpdateService } from './services/update.service';
import { environment } from "src/environments/environment";

@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
    styleUrls: ["app.component.scss"],
})
export class AppComponent {
    constructor(private platform: Platform, private splashScreen: SplashScreen, private statusBar: StatusBar,
        private fcm: FCM, private themeService: ThemeService, private utils: UtilsService, private http: HttpService,
        private notificationsService: NotificationsService, private device: Device, private plt: Platform, private updateService: UpdateService,
        private router: Router) {


        this.initializeApp();
    }

    initializeApp() {


        this.platform.ready()
        .then(() => {
            this.updateService.checkForUpdate()
            this.statusBar.styleDefault();
            this.statusBar.backgroundColorByHexString("#ffffff");
            this.splashScreen.hide();

            this.initTheme();
            this.validateToken();
            this.getNotificacionesPendientes();
            this.saveDevice();

            //localStorage.setItem('notification_type', 'empleado')

            this.fcm.requestPushPermission().then((res) => { console.log('requestPushPermission', res) });
            this.fcm.onNotification().subscribe(async(data: any) => {
                this.getNotificacionesPendientes();
                //console.log('onNotification', data);


                if(data.wasTapped) {

                    console.log('OPEN', data)

                    this.router.navigateByUrl('notifications?redirect');
                }

                // this.fcm.createNotificationChannel({
                //     id: this.device.uuid,
                //     name: data.title,
                //     description: data.body,
                //     importance: 'high',
                //     visibility: 'public',
                //     sound: 'alert_sound',
                //     vibration: true
                // }).then(res => console.log('createNotificationChannel', res)).catch(err => console.log('createNotificationChannel err', err))

                // const rest = this.fcm.getInitialPushPayload().then(res => console.log('RES' , res.wasTapped))
                // console.log('getInitialPushPayload', rest)

                

            });

            
        })
    }   

    async saveDevice() {
        if (this.device.uuid) {
            const data = {
                cordova: this.device.cordova,
                manufacturer: this.device.manufacturer,
                model: this.device.model,
                platform: this.device.platform,
                serial: this.device.serial,
                uuid: this.device.uuid,
                version: this.device.version,
            };
            console.log(data);
            await this.http.post("/device", data, true).subscribe((res) => { });
        }
    }

    initTheme() {
        const ajustes = JSON.parse(
            localStorage.getItem("ajustes_tepremia") || "{}"
        );
        if (!this.utils.isEmpty(ajustes)) {
            const theme = {
                primary: ajustes.color1 || "",
                secondary: ajustes.color2 || "",
                tertiary: ajustes.color3 || "",
            };
            this.themeService.setTheme(theme);
        } else {
            this.themeService.setDefaultTheme();
        }
    }

    async validateToken() {
        await this.http.post("/auth/me", {}, true).subscribe(
            (res) => { },
            (err) => {
                if (err.status === 401) {
                    localStorage.removeItem("ajustes_tepremia");
                    localStorage.removeItem("id");
                    localStorage.removeItem("isLoged");
                    localStorage.removeItem("access_token");
                    localStorage.removeItem("email");
                    localStorage.removeItem("nombre");
                    localStorage.removeItem("saldo");
                    this.themeService.setDefaultTheme();
                }
            }
        );
    }

    async getNotificacionesPendientes() {
        const codprog = localStorage.getItem("programa");
        const id = localStorage.getItem("id");
        await this.http
            .get(`/auth/notificaciones/pendientes/${id}/${codprog}`, {})
            .subscribe((res) => {
                if(res.data.hasOwnProperty('total') && res.data.total > 0 ) {
                    this.utils.mensajeInfo('Tienes notificaciones pendientes, no olvides revisarlas')
                }
                //console.log('getNotificacionesPendientes', res)

                localStorage.setItem("notificaciones", JSON.stringify(res.data));
                this.notificationsService.updateCount(res.data);
            });
    }
}