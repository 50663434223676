import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditCard'
})
export class CreditCardPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    let numbers;

    if (args[0] === 1) {
      numbers = value.substring(0, 4);
    } else if (args[0] === 2) {
      numbers = value.substring(5, 9);
    } else if (args[0] === 3) {
      numbers = value.substring(10, 14);
    } else if (args[0] === 4) {
      numbers = value.substring(15, 19);
    }

    return numbers.padEnd(4, '●');
  }

}
