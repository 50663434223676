import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'imagen',
})
export class ImagenPipe implements PipeTransform {

  transform(value: string, ...args) {
    const nombreCategoria = value.toLowerCase();
    return '/assets/imgs/categorias/' + nombreCategoria.split(' ').join('_') + '.png';
  }
}
