import { Directive, HostListener  } from '@angular/core';

@Directive({
  selector: '[appOnlyNumbers]'
})
export class OnlyNumbersDirective {

  constructor() { }

  @HostListener('keypress')
  onkeypress(e: any) {
    const event = e || window.event;
    if (event) {
      return this.isNumberKey(event);
    }
  }

  isNumberKey(event) {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }
}
