import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HttpService} from '../../../services/http.service';

@Component({
  selector: 'app-winner',
  templateUrl: './winner.component.html',
  styleUrls: ['./winner.component.scss'],
})
export class WinnerComponent implements OnInit {
  @Input() puntos;

  constructor(private modalController: ModalController, private http: HttpService) { }

  ngOnInit() {}

  async closeModal(card = null) {
    this.http.post('/auth/saldos/leer', {id: this.puntos.id}, true).subscribe(res => {});
    await this.modalController.dismiss(card);
  }
}
