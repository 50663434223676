import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Device } from '@ionic-native/device/ngx';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { UtilsService } from './services/utils.service';
import { HttpService } from './services/http.service';
import { ThemeService } from './services/theme.service';
import { NotificationsService } from './services/observers/notifications.service';

import { PipesModule } from './pipes/pipes.module';
import { ComponentsModule } from './components/components.module';
import { DirectivesModule } from './directives/directives.module';
import { SimpleMaskModule } from 'ngx-ion-simple-mask';
import { NgAisModule } from 'angular-instantsearch';

import { Stripe } from '@ionic-native/stripe/ngx';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { CountUpModule } from 'ngx-countup';
import { SuperTabsModule, SuperTabs } from '@ionic-super-tabs/angular';

import { AppVersion } from '@ionic-native/app-version/ngx'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        PipesModule,
        ComponentsModule,
        DirectivesModule,
        SimpleMaskModule,
        CountUpModule,
        SuperTabsModule.forRoot(),
        NgAisModule.forRoot(),
        IonicModule.forRoot()
    ],
    providers: [
        FCM,
        Stripe,
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        UtilsService,
        HttpService,
        ThemeService,
        Keyboard,
        NotificationsService,
        Camera,
        Device,
        AppVersion,
        InAppBrowser,
        SuperTabs
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
