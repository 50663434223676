import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HttpService } from '../../../services/http.service';
import { CupertinoPane, CupertinoSettings} from 'cupertino-pane';
import { UtilsService } from '../../../services/utils.service';
import { Router } from '@angular/router';

import * as moment from 'moment';
moment.locale('es');

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit {

  moment = moment;
  @Input() compra: any;

  segment = 'compra';
  show_link:number = null;
  valid_link = [];

  settings: CupertinoSettings = {
    parentElement: '.compra-detail',
    lowerThanBottom: false,
    breaks: {
      top: {
        enabled: true,
        height: 500,
        bounce: true,
      },
      middle: {
        enabled: true,
        height: 350,
        bounce: true,
      },
      bottom: {
        enabled: false,
        height: 150,
        bounce: true,
      }
    },
    bottomClose: false
  };
  pane: CupertinoPane;

  constructor(private modalController: ModalController, private http: HttpService, private utils: UtilsService,
              private router: Router) { }

  ngOnInit() {
    this.pane = new CupertinoPane('.cupertino-pane', this.settings);
    this.pane.present({animate: true});

    if(this.compra.response.Vouchers) {
      let regex = new RegExp(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/);
      for(let vouch in this.compra.response.Vouchers) {

        if(regex.test(this.compra.response.Vouchers[vouch].VoucherLink)){
          console.log('valid link')
          this.valid_link.push(true)
        }
        else {
          console.log('link not valid')
          this.valid_link.push(false)
        }
      }
    }
  }

  async closeModal() {
    await this.modalController.dismiss({});
  }

  passProduct() {
    this.closeModal();

    this.utils.setData('product', JSON.stringify(this.compra.producto_original));

    if (this.compra.producto.type == 'Fisico') {
      this.router.navigateByUrl(`/tabs/about/products/${this.compra.producto_original.categoria}/${this.compra.producto.id}`);
    } else if (this.compra.producto.type == 'TEA') {
      this.router.navigateByUrl(`/tabs/about/products/${this.compra.producto_original.provider}/${this.compra.producto.id}`);
    } else if (this.compra.producto.type == 'Voucher') {
      this.router.navigateByUrl(`/tabs/about/products/${this.compra.producto_original.MerchantName}/${this.compra.producto.id}`);
    }

  }

  showLink(index) {
    if(index == this.show_link) {
      this.show_link = null;
    }
    else {
      this.show_link = index;
    }
  }
}
