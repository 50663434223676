import { NgModule } from '@angular/core';
import { OnlyNumbersDirective } from './only-numbers.directive';

@NgModule({
  declarations: [OnlyNumbersDirective],
  imports: [],
  exports: [OnlyNumbersDirective],
  providers: [OnlyNumbersDirective],
})

export class DirectivesModule {

}
