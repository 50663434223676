import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'tituloCorto',
})
export class TituloCortoPipe implements PipeTransform {
  /**
   * Takes a text value and compares its character length to resize de text and append 3 final dots
   */
  transform(value: string, ...args) {
    let texto = value;
    if (texto.length > 15){
      texto = texto.substr(0, 15) + '...';
    }
    return texto;
  }
}
