import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular'
import { Plugins } from '@capacitor/core';
import { AppVersion } from '@ionic-native/app-version/ngx'
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx'

import { version } from '../../../package.json'

// const { version } = require('../../../package.json')

const { NativeMarket } = Plugins;

interface AppUpdate {
    currect: string;
    enabled: string;
    msg?: {
        title: string;
        msg: string;
        btn: string;
    },
    majorMsg?: {
        title: string;
        msg: string;
        btn: string;
    },
    minorMsg?: {
        title: string;
        msg: string;
        btn: string;
    }
}

@Injectable({
  providedIn: 'root'
})

export class UpdateService {

    updateTepremia = 'https://apps-cordoba.s3.amazonaws.com/version.json'
    maintenanceTepremia = 'https://apps-cordoba.s3.amazonaws.com/maintenance.json'

    constructor(private http: HttpClient, private alertCtrl: AlertController, private appversion: AppVersion, private iab: InAppBrowser, private plt: Platform) {

    }

    async checkForUpdate() {

        this.http.get(this.updateTepremia).subscribe(async(info: AppUpdate) => {


            if(!info.enabled) {
                this.presentAlert(info.msg.title, info.msg.msg, info.msg.btn)
            }
            else {

                // const versionNumber = await this.appversion.getVersionNumber();

                const splittedVersion = version.split('.');
                const serviceVersion = info.currect.split('.');

                if(serviceVersion[0] > splittedVersion[0]) {
                    this.presentAlert(info.majorMsg.title, info.majorMsg.msg, info.majorMsg.btn, true)
                }       
                else if(serviceVersion[1] > splittedVersion[1]) {
                    this.presentAlert(info.minorMsg.title, info.minorMsg.msg, info.minorMsg.btn, true)
                }

                
            }
        })
    }

    openAppstpreEntry() {

        if(this.plt.is('android')) {
            NativeMarket.openStoreListing({
                appId: 'io.ionic.starter'
            })
        }
        else {
           this.iab.create('itms-apps://itunes.apple.com/mx/app/tepremia-mx/id1552297639')
        }
    }

    async presentAlert(header, message, buttonText = '', allowClose = false) {

        const buttons: any = [];

        if(buttonText != '') {
            buttons.push({
                text: buttonText,
                handler: () => {
                    this.openAppstpreEntry()
                }
            })
        }

        if(allowClose) {
            buttons.push({
                text: 'Cerrar',
                role: 'cancel'
            })
        }

        const alert = await this.alertCtrl.create({
            header,
            message,
            buttons,
            backdropDismiss: allowClose
        });

        await alert.present()
    }
}
