import {Component, Input, OnInit} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilsService } from '../../../services/utils.service';
import { HttpService } from '../../../services/http.service';
import { Router} from '@angular/router';
import {FormBuilder, Validator, Validators} from '@angular/forms';
import { IonNav } from '@ionic/angular';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import {HomePage} from "../../../pages/home/home.page";

@Component({
  selector: 'app-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.scss'],
})
export class BuyComponent implements OnInit {

  @Input() producto: any;
  ajustes_tepremia = JSON.parse(localStorage.getItem('ajustes_tepremia')) || [];
  valor_moneda = Number(this.ajustes_tepremia.valor_moneda);
  moneda = this.ajustes_tepremia.moneda;
  saldo;
  direcciones = [];
  updated_price:number
  //no_hay_direccion:boolean = true;
  selectedDireccion = null;
  cantidad = 1;
  limite = JSON.parse(localStorage.getItem('limit'))

  sliderConfig = {
    slidesPerView: 2,
    spaceBetween: -10,
    direction: 'vertical',
    autoHeight: false
  };

  formProducto;
  formStarbucks;
  formCinepolis;
  formTEA;
  formAmilon;
  
  constructor(private utils: UtilsService, private http: HttpService, private modalController: ModalController,
              private router: Router, private formGroup: FormBuilder) { }

  async ngOnInit() {
    console.log('buy producto:', this.producto)
    console.log('ajutes:', this.ajustes_tepremia)
    this.getDirecciones();
    this.setPrice();

    this.formProducto = this.formGroup.group({
      cantidad: [1, Validators.required],
      direccion: ['', Validators.required],
    });

    this.formStarbucks = this.formGroup.group({
      cantidad: [1, Validators.required]
    });

    this.formCinepolis = this.formGroup.group({
      cantidad: [1, Validators.required],
    });

    this.formAmilon = this.formGroup.group({
      cantidad: [1, Validators.required],
    });

    this.formTEA = this.formGroup.group({
      telefono: ['', Validators.compose([Validators.required, Validators.minLength(14), Validators.maxLength(14)])]
    });
  }

  async calculate() {
    const precio = (this.producto.price / this.valor_moneda) * this.cantidad;
    if (this.saldo <= precio) {
      const dif = precio - this.saldo;
      await this.utils.toast('warning', `te hacen falta ${Math.ceil(dif)} ${this.moneda}`, 2500);
    }
  }

  async getDirecciones() {
    await this.utils.loader('Cargando direcciones');
    this.http.get('/auth/direcciones', {empleado_id: this.ajustes_tepremia.empleado_id}).subscribe(res => {
      this.utils.removeLoader();
      this.direcciones = res.data;
      //console.log('DIR:', res.data)
    }, err => {
      this.utils.removeLoader();
    });

    
  }

  setDireccion(direccion) {
    this.selectedDireccion = direccion;
    this.formProducto.controls.direccion.setValue(this.selectedDireccion);
  }

  setPrice() {
    if(!this.ajustes_tepremia.sobreprecio){
        this.updated_price = Math.round(this.producto.price / this.valor_moneda);
    }

    else if(this.producto.type === 'Voucher') {
      this.updated_price = Math.round(((this.producto.price) + ((this.producto.price * this.ajustes_tepremia.sobreprecio_digital) / 100)) / this.valor_moneda);
    }

    else if(this.producto.type === 'Cinepolis') {
      this.updated_price = Math.round(((this.producto.price) + ((this.producto.price * this.ajustes_tepremia.sobreprecio_digital) / 100)) / this.valor_moneda);
    }

    else if(this.producto.type === 'TEA') {
        this.updated_price = Math.round(((this.producto.price) + ((this.producto.price * this.ajustes_tepremia.sobreprecio_digital) / 100)) / this.valor_moneda);
    }

    else {
        this.updated_price = Math.round(((this.producto.price) + ((this.producto.price * this.ajustes_tepremia.sobreprecio) / 100)) / this.valor_moneda);
    }
  }


  async canjear(data) {

    if((this.limite.merchant !== null && this.limite.limit !== null) && this.cantidad > this.limite.limit) {
      await this.utils.toast('warning', `Solo puedes comprar ${this.limite.limit} por movimiento`, 2500);
      return
    }

    if(this.direcciones.length === 0 && (data.producto.id.toLowerCase().includes('celx') || data.producto.id.toLowerCase().includes('cpdb') || data.producto.id.toLowerCase().includes('cora') ) ) {
      this.utils.mensajeError('Error','No tienes direcciones registradas, por favor registra una.')
        return
    }

    if(typeof(data.producto.id) == 'number') {
        data.producto.id = data.producto.id.toString();
    }

    if(data.direccion_id === '' && (data.producto.id.toLowerCase().includes('celx') || data.producto.id.toLowerCase().includes('cpdb') || data.producto.id.toLowerCase().includes('cora') )) {
      this.utils.mensajeError('Error','No has seleccionado una dirección, por favor selecciona una.')
      //console.log('proveedor:', data.producto.id)
        return
    }

    let direccion = this.direcciones.find( direccion => direccion.id == data.direccion_id)
    //console.log(direccion)
    let direccion_incompleta = false
    let message = ''
    for(let key in direccion) {
      if(key !== 'observaciones' && key !== 'favorita') {
        if(!direccion[key]) {
          direccion_incompleta = true
          switch(key) {
            case 'telefono_de_contacto':
              message = message + ` Teléfono de Contacto`
              break;
            case 'nombre':
              message = message + ` Nombre`
              break;
            case 'cp':
              message = message + ` Código Postal`
              break;
            case 'estado':
              message = message + ` Estado`
                break;
            case 'municipio':
              message = message + ` Municipio`
              break;
            case 'rfc':
              message = message + ` RFC`
              break;
            case 'asentamiento':
              message = message + ` Asentamiento`
              break;
            case 'calle':
              message = message + ` Calle`
              break;
            case 'calle_1':
              message = message + ` Entre calle`
              break;
            case 'calle_2':
              message = message + ` Entre calle`
              break;
            default:
              console.log('no match')
              break;
          }

        }

      }
    }

    if(direccion_incompleta && message){
        this.utils.mensajeError('Incompleto',`Para seleccionar esta dirección es necesario completar los siguientes datos: 
          ${message}, o selecciona otra`)
        return
    }

    if(Number(this.saldo) < Number(data.cantidad * data.precio)) {
        this.utils.mensajeError('Error','No tienes el saldo suficiente para realizar la compra')
        return
    }

    if(!this.ajustes_tepremia.sobreprecio){
        data.precio = Math.round(this.producto.price / this.valor_moneda);
        data.total = data.precio * data.cantidad
    }

    else if(this.producto.type === 'Voucher') {
        data.precio = Math.round(((this.producto.price) + ((this.producto.price * this.ajustes_tepremia.sobreprecio_digital) / 100)) / this.valor_moneda);
        data.total = data.precio * data.cantidad
    }

    else if(this.producto.type === 'Cinepolis') {
        data.precio = Math.round(((this.producto.price) + ((this.producto.price * this.ajustes_tepremia.sobreprecio_digital) / 100)) / this.valor_moneda);
        data.total = data.precio * data.cantidad
    }

    else if(this.producto.type === 'TEA') {
        data.precio = Math.round(((this.producto.price) + ((this.producto.price * this.ajustes_tepremia.sobreprecio_digital) / 100)) / this.valor_moneda);
        data.total = data.precio * data.cantidad
    }

    else {
        data.precio = Math.round(((this.producto.price) + ((this.producto.price * this.ajustes_tepremia.sobreprecio) / 100)) / this.valor_moneda);
        data.total = data.precio * data.cantidad
    }

    console.log('DATA:', data)
    console.log('comprado')
    
    await this.utils.loader('Comprando');

    this.http.post('/auth/compras', data, true).subscribe(res => {
      this.utils.removeLoader();
      this.utils.mensajeSuccess('Compra exitosa', res.data);
      localStorage.setItem('saldo', String(this.saldo - data.total));
      this.closeModal();
      this.router.navigateByUrl('detail');

    }, err => {
      console.error(err)
      this.utils.mensajeSuccess('Error', 'Hubo un error al procesar tu compra, intentalo de nuevo más tarde');
      this.utils.removeLoader();
    });
  }

  async comprarProducto() {
    // todo corregir
    // if (!this.formProducto.valid) {
    //   await this.utils.toast('warning', 'Llena todos los campos');
    // } else {
      const value = this.formProducto.value;

      const data = {
        cantidad: this.cantidad,
        telefono: null,
        direccion_id: this.selectedDireccion ? this.selectedDireccion.id : '',
        codigo: this.producto.id,
        producto: this.producto,
        producto_original: JSON.parse(this.utils.getData('product')),
        precio: this.producto.price / this.valor_moneda,
        total: (this.producto.price / this.valor_moneda) * this.cantidad,
        empleado_id: this.ajustes_tepremia.empleado_id,
        programa_id: this.ajustes_tepremia.programa_id,
        nombre_producto: this.producto.name
      };

      await this.canjear(data);


    // }
  }

  async comprarAmilon() {
      
    if (!this.formAmilon.valid) {
      await this.utils.toast('warning', 'Escribe una cantidad válida');
    } else {

      const value = this.formAmilon.value;

      const data = {
        cantidad: this.cantidad,
        telefono: null,
        direccion_id: this.selectedDireccion ? this.selectedDireccion.id : '',
        codigo: this.producto.id,
        producto: this.producto,
        producto_original: JSON.parse(this.utils.getData('product')),
        precio: this.producto.price / this.valor_moneda,
        total: (this.producto.price / this.valor_moneda) * this.cantidad,
        empleado_id: this.ajustes_tepremia.empleado_id,
        programa_id: this.ajustes_tepremia.programa_id,
        nombre_producto: this.producto.name
      };

      await this.canjear(data);
    }
  }

  async comprarTEA() {
    if (!this.formTEA.valid) {
      await this.utils.toast('warning', 'Escribe el número de teléfono');
    } else {

      let telefono = this.formTEA.value.telefono;
      telefono = telefono.replace(/\s/g, '');
      telefono = telefono.replace('(', '');
      telefono = telefono.replace(')', '');
      telefono = telefono.replace('-', '');

      const data = {
        cantidad: 1,
        telefono,
        direccion_id: this.selectedDireccion ? this.selectedDireccion.id : '',
        codigo: this.producto.id,
        producto: this.producto,
        producto_original: JSON.parse(this.utils.getData('product')),
        precio: this.producto.price / this.valor_moneda,
        total: this.producto.price / this.valor_moneda,
        empleado_id: this.ajustes_tepremia.empleado_id,
        programa_id: this.ajustes_tepremia.programa_id,
        nombre_producto: this.producto.name
      };

      await this.canjear(data);
    }
  }

  async closeModal() {
    await this.modalController.dismiss({});
  }

  ionViewDidEnter() {
    this.saldo = localStorage.getItem('saldo');
  }

  nuevaDireccion() {
    localStorage.setItem('returnUrl', this.router.url);
    this.closeModal();
    this.router.navigateByUrl('/addresses/address');
  }
}
