import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    private notificationSubject = new Subject();

    constructor() { }

    updateCount(type) {
        const notificaciones = JSON.parse(localStorage.getItem('notificaciones'));

        if (type === 'usuario') {
            notificaciones.usuario -= 1;
        } 
        else if (type === 'programa') {
            notificaciones.programa -= 1;
        }

        notificaciones.total = notificaciones.usuario + notificaciones.programa;
        localStorage.setItem('notificaciones', JSON.stringify(notificaciones));
        console.log('notificaciones', notificaciones)
        this.notificationSubject.next(notificaciones);
    }

    getObservable(): Subject<any> {
        return this.notificationSubject;
    }
}

