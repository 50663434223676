import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UtilsService } from './utils.service'

@Injectable({
    providedIn: 'root'
})


export class HttpService {
    url = environment.url;
    token = '';
    headers = {}

    constructor(public http: HttpClient, private utils: UtilsService) {

        this.headers = {
            "Content-Type": "application/x-www-form-urlencoded; charset=utf-8", 
            'Accept': 'application/json, text/plain',
            "cache-control": "no-cache", 
            "Access-Control-Allow-Origin": "*", 
            "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers",
            "Access-Control-Allow-Credentials" : "true",
            "Access-Control-Allow-Methods" : "GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT",  
        }

    }

    getWhitOutBase(path: string, params?: {}): any {
        const httOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
                Authorization: this.getToken()
            }),
            params
        };
        return this.http.get(path, httOptions);
    }

    get(path: string, params?: {}): any {
        const httOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
                Authorization: this.getToken()
            }),
            params
        };
        return this.http.get(this.url + path, httOptions);
    }

    post(path: string, params: any, headers: boolean): any {
        if (headers) {
            const httOptions = {
                headers: new HttpHeaders({
                    Authorization: this.getToken()
                })
            };

            console.log('path => ', path)

            return this.http.post(this.url + path, params, httOptions);
        } 
        else {
            console.log(this.url + path)
            return this.http.post(this.url + path, params);
        }
    }

    put(path: string, params: any, headers: boolean): any {
        if (headers) {
            const httOptions = {
                headers: new HttpHeaders({
                    Authorization: this.getToken()
                })
            };
            return this.http.put(this.url + path, params, httOptions);
        } else {
            return this.http.put(this.url + path, params);
        }
    }

    delete(path: string, params?: {}): any {
        const httOptions = {
            headers: new HttpHeaders({
                Authorization: this.getToken()
            }),
            body: params
        };

        return this.http.delete(this.url + path, httOptions);
    }

    getToken() {
        const token = localStorage.getItem('access_token');
        return this.token = 'Bearer ' + token;
    }

    validateToken(token: string) {
        this.post('/auth/me', { token }, false);
    }

    getHost() {
        return environment.url.replace('/api', '');
    }

    getImagen() {
        const img = localStorage.getItem('imagen') || '';
        if (img != 'null') {
            return this.getHost() + img;
        } else {
            const hash = localStorage.getItem('hash_email');
            return 'https://www.gravatar.com/avatar/' + hash;
        }
    }
}
