import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit {

  @Input() info;
  @Input() image;

  constructor() { }

  ngOnInit() {
    console.log(this.info)
  }

}
