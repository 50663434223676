import {Component, OnInit} from '@angular/core';
import { isPlatform } from '@ionic/angular';

@Component({
  selector: 'app-comprar',
  templateUrl: './comprar.component.html',
  styleUrls: ['./comprar.component.scss'],
})
export class ComprarComponent implements OnInit {

  runningPlatform: string

  constructor() { }

  clase = 'up';

  ngOnInit() {
    this.checkPlatform()
  }


  checkPlatform() {
    let platform: string = ''
    if(isPlatform('android')){
      platform = 'android'
    }

    if(isPlatform('ios')) {
      platform = 'ios'
    }

    this.runningPlatform = platform
  }

  onScroll(event) {
    if (event.detail.deltaY > 0) {
      this.clase = 'down';
    } else {
      this.clase = 'up';
    }
  }
}
