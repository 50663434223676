import { Injectable } from "@angular/core";
import {
  LoadingController,
  AlertController,
  ToastController,
} from "@ionic/angular";
import { CameraOptions, Camera } from "@ionic-native/camera/ngx";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  private loading: any;

  private colors = {
    success: "primary",
    error: "danger",
    warning: "warning",
    info: "secondary",
  };

  private colores = [
    "rojo",
    "rosa",
    "morado",
    "morado-oscuro",
    "indigo",
    "azul",
    "azul-claro",
    "cyan",
    "turquesa",
    "verde",
    "verde-claro",
    "lima",
    "amarillo",
    "ambar",
    "naranja",
    "naranja-oscuro",
    "cafe",
    "gris",
    "azul-gris",
  ];

  constructor(
    private loadingController: LoadingController,
    private alertController: AlertController,
    private toastController: ToastController,
    private camera: Camera
  ) {}

  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  error(error) {
    let msgError = "";
    if (error === Object(error)) {
      for (const key in error) {
        msgError += error[key][0] + "<br>";
      }
    } else {
      msgError += error;
    }

    return msgError;
  }

  async loader(message) {
    this.loading = await this.loadingController.create({
      message,
    });

    await this.loading.present();
  }

  async removeLoader() {
    await this.loadingController.dismiss();
  }

  async toast(type, message, time = 2500) {
    const toast = await this.toastController.create({
      message,
      color: this.colors[type],
      buttons: [
        {
          side: "end",
          role: "cancel",
          text: "ok",
        },
      ],
    });
    await toast.present();
  }

  async mensajeSuccess(titulo: string, mensaje: string) {
    const alert = await this.alertController.create({
      header: titulo,
      message: mensaje,
      cssClass: "alertaSuccess",
      buttons: [
        {
          text: "OK",
          handler: () => {},
        },
      ],
    });
    await alert.present();
  }

  async mensajeError(titulo: string, mensaje: string) {
    const alert = await this.alertController.create({
      header: titulo,
      message: mensaje,
      cssClass: "alertaError",
      buttons: [
        {
          text: "OK",
          handler: () => {},
        },
      ],
    });
    await alert.present();
  }

  async mensajeInfo(mensaje) {
    const alert = await this.alertController.create({
      header: "Aviso",
      message: mensaje,
      cssClass: "alertaInfo",
      buttons: [
        {
          text: "OK",
          handler: () => {},
        },
      ],
    });
    await alert.present();
  }

  setData(key, value) {
    localStorage.setItem(key, value);
  }

  getData(key) {
    return localStorage.getItem(key);
  }

  createProduct(product) {
    console.log(product);

    const producto = {
      price: "",
      image: "",
      name: "",
      description: "",
      id: "",
      type: "",
      description_large: "",
    };

    // set price
    if (product.Price) {
      producto.price = product.Price;
    }
    if (product.price) {
      producto.price = product.price;
    }
    if (product.monto) {
      producto.price = product.monto;
    }

    // set image
    if (product.ImageUrl) {
      producto.image = product.ImageUrl;
    }
    if (product.imageUrl) {
      producto.image = product.imageUrl;
    }
    if (product.provider) {
      producto.image = `/assets/imgs/recargas/${product.provider.toLowerCase()}.png`;
    }

    // set name
    if (product.Name) {
      producto.name = product.Name;
    }
    if (product.name) {
      producto.name = product.name;
    }
    if (product.nombre) {
      producto.name = product.nombre;
    }

    // set description
    if (product.MerchantShortDescription) {
      producto.description = product.MerchantShortDescription;
    }
    if (product.descripcion) {
      producto.description = product.descripcion;
    }
    if (product.tipo) {
      producto.description = product.tipo;
    }

    // set id
    if (product.ProductCode) {
      producto.id = product.ProductCode;
    }
    if (product.objectID) {
      producto.id = product.objectID;
    }
    if (product.hashId) {
      producto.id = product.hashId;
    }

    // set type
    if (product.ProductType) {
      producto.type = product.ProductType;
    } else if (product.tipo) {
      producto.type = "TEA";
    } else {
      producto.type = "Fisico";
    }

    if (product.info) {
      producto.description_large = product.info;
    } else if (product.MerchantShortDescription) {
      producto.description_large = product.MerchantLongDescription;
    }

    return producto;
  }

  colorRandom(index) {
    if (index > 18) {
      index = this.random(0, 18);
    }
    return this.colores[index];
  }

  random(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getCameraOptions(sourceType) {
    const cameraOptions: CameraOptions = {
      quality: 100,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
      targetHeight: 800,
      targetWidth: 800,
      sourceType,
    };

    return cameraOptions;
  }

  createThumbnail(base64, ancho = 150, alto = 150, calidad = 1, callback) {
    const canvas = document.createElement("canvas");
    const image = new Image();

    image.onload = () => {
      canvas.width = ancho;
      canvas.height = alto;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, ancho, alto);
      callback(canvas.toDataURL("image/png", calidad));
    };
    image.src = base64;
  }

  base642TBlob(base64, contentType = "image/jpeg") {
    const sliceSize = 512;
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob as File;
  }
}
