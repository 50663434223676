import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from '../../../services/utils.service';
import { HttpService } from '../../../services/http.service';
import { AlertController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Stripe } from '@ionic-native/stripe/ngx';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-buy-points',
  templateUrl: './buy-points.component.html',
  styleUrls: ['./buy-points.component.scss'],
})
export class BuyPointsComponent implements OnInit {

  @Input() datos: any;

  cardForm: FormGroup;
  isSubmitted = false;

  constructor(private formBuilder: FormBuilder, public utils: UtilsService, private http: HttpService, private router: Router,
              private stripe: Stripe, public alertController: AlertController, private modalController: ModalController) { }

  async ngOnInit() {
    this.initForm();
    await this.stripe.setPublishableKey(environment.stripe_key);
  }

  initForm() {
    this.cardForm = this.formBuilder.group({
      name: ['', Validators.required],
      card: ['', Validators.required],
      expiry: ['', Validators.required],
      cvc: ['', Validators.required],
    });
  }

  async submitForm() {
    this.isSubmitted = true;
    if (!this.cardForm.valid) {
      await this.utils.toast('error', 'LLena todos los campos requeridos');
    } else {

      await this.utils.loader('Validando tarjeta');

      const paymentMethod = await this.stripe.createCardToken({
        number: this.cardForm.value.card.replace(/\s/g, ''),
        expMonth: this.cardForm.value.expiry.split('/')[0],
        expYear: this.cardForm.value.expiry.split('/')[1],
        cvc: this.cardForm.value.cvc,
        name: this.cardForm.value.name
      }).then(async card => {
        this.datos.card_id = card.id;

        this.http.post('/auth/saldos/compraUnica', this.datos, true).subscribe(res => {
          this.utils.removeLoader();
          if (res.data) {
            this.utils.toast('success', 'El pago se realizo con éxito.');
            this.closeModal(true);
          } else {
            this.utils.toast('error', 'Hubo un error al procesar tu compra');
          }
        }, error => {
          this.utils.removeLoader();
          this.utils.toast('error', this.utils.error(error.error));
        });
      }).catch(err => {
        this.utils.removeLoader();
        this.utils.toast('warning', err, 2500);
      });
    }
  }

  async closeModal(card = null) {
    await this.modalController.dismiss(card);
  }
}
