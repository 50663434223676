import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/tabs/home',
    pathMatch: 'full',
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tickets',
    loadChildren: () => import('./pages/tickets/tickets.module').then(m => m.TicketsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/account/profile/profile.module').then(m => m.ProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detail',
    loadChildren: () => import('./pages/account/detail/detail.module').then(m => m.DetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notificationsDetail/notificationsDetail.module').then(m => m.NotificationsDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'addresses',
    loadChildren: () => import('./pages/account/addresses/addresses.module').then(m => m.AddressesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'password',
    loadChildren: () => import('./pages/account/password/password.module').then( m => m.PasswordPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'payments',
    loadChildren: () => import('./pages/account/payments/payments.module').then( m => m.PaymentsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'points',
    loadChildren: () => import('./pages/account/points/points.module').then( m => m.PointsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'about/:item',
    loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'about/products/:item',
    loadChildren: () => import('./pages/products/products.module').then( m => m.ProductsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'about/service/:item',
    loadChildren: () => import('./pages/products-services/products-services.module').then( m => m.ProductsServicesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'about/products/:item/:product',
    loadChildren: () => import('./pages/product/product.module').then( m => m.ProductPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'forgot',
    loadChildren: () => import('./pages/forgot/forgot.module').then( m => m.ForgotPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: '**',
    redirectTo: '/tabs/home'
  },
  

];
@NgModule({
  imports: [
  RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
