import { Component, Input, OnInit } from '@angular/core';
import { HttpService } from '../../../services/http.service';

@Component({
  selector: 'app-header-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilComponent implements OnInit {
  @Input() title;
  @Input() subtitle;
  @Input() icon;
  @Input() back;

  nombre = localStorage.getItem('nombre');
  imagen = this.http.getImagen();

  constructor(private http: HttpService) { }

  ngOnInit() { }
}
