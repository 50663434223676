import { Component, Input, OnInit } from '@angular/core';
import { HttpService } from '../../../services/http.service';

@Component({
  selector: 'app-header-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailsComponent implements OnInit {
  @Input() title;
  @Input() subtitle;
  @Input() icon;
  @Input() saldo;
  @Input() moneda;

  nombre = localStorage.getItem('nombre');
  imagen = JSON.parse(localStorage.getItem('ajustes_tepremia')).logo;

  options;


  constructor(private http: HttpService) { }

  ngOnInit() {
    this.options = {
      decimalPlaces: 2,
      suffix: ' ' + this.moneda
    };

  }

}
