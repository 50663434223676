import { Component, Input, OnInit } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
})
export class GeneralComponent implements OnInit {
  @Input() title;
  @Input() subtitle;
  @Input() icon;
  @Input() saldo;
  @Input() moneda;

  nombre = localStorage.getItem('nombre');
  imagen = JSON.parse(localStorage.getItem('ajustes_tepremia')).logo;

  options;


  constructor(private http: HttpService, private router: Router) { }

  ngOnInit() {
    this.options = {
      decimalPlaces: 2,
      suffix: ' ' + this.moneda
    };

  }


  redirect() {

    console.log('here')

    this.router.navigate(['/tabs/home']);

  }

}
